import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IPaymentList } from '../interfaces';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'payments', resettable: true })
export class PaymentStore extends Store<{ data: IPaymentList[] }> {
  constructor() {
    super({ data: [] });
  }
}
