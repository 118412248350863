import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IPaymentList } from '../interfaces';
import { PaymentStore } from './payment.store';

@Injectable({ providedIn: 'root' })
export class PaymentQuery extends Query<{ data: IPaymentList[] }> {
  public payments$: Observable<IPaymentList[]> = this.select((state) => state.data).pipe(
    debounceTime(100),
  );

  constructor(protected override store: PaymentStore) {
    super(store);
  }
}
